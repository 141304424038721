// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
/* purgecss start ignore */
@import '../vendors/font-awesome/font-awesome';
@import '../vendors/owl-carousel2/owl.carousel';
@import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
// @import '../vendors/parallax-scroll/normalize';
// @import '../vendors/parallax-scroll/main';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    .parallax, .parallax-slow, .parallax-fast {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}


section.section-02-salles {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    transition: .5s;
    width: 100%;
    div.content-all-sections {
      width: 100%;
        @media screen and (max-width: $size-max-m-1) {
            background-image: linear-gradient(90deg, rgba(134, 134, 134, 0.356), rgb(255, 255, 255));
            transition: .5s;
        }
        &::after {
            content: "";
            top: 0;
            left: 0;
            width: 50%;
            height: 100%;
            z-index: -1;
            background: $color-white;
            position: absolute;
            transition: .3s;
            @media screen and (max-width: $size-max-m-1) {
                display: none;
                transition: .5s;
            }
        }
        &::before {
            content: "";
            top: 0;
            right: 0;
            width: 50%;
            height: 100%;
            z-index: -1;
            background: $color-2;
            background-image: linear-gradient(85deg, rgba(134, 134, 134, 0.956), rgb(255, 255, 255));
            position: absolute;
            transition: .3s;
            @media screen and (max-width: $size-max-m-1) {
                display: none;
                transition: .5s;
            }
        }
    }
    div.header-section {
        width: 100%;
        min-height: 50px;
        transition: .5s;
        padding: 15px 45px;        
        display: flex;
        align-items: center;
        justify-content: flex-start;
        @media screen and (max-width: $size-max-m-2) {
            padding: 15px;
            transition: .5s;
        }
        h3 {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-size: $font-size-50;
            color: $color-text-gris;
            @extend .font-3-normal;
            font-family: $font-family-4;
            margin-bottom: 2rem;   
            padding-left: 3vw;
            margin-top: 5rem;   
            transition: .5s;  
            @media screen and (max-width: $size-max-m-2) {
                margin: 0;
                padding: 15px;
                margin-top: 45px;
                text-align: center;
                padding-bottom: 0;
                transition: .5s;
            } 
        }
    }
    div.container-fluid.row {
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .5s;
        width: 100%;
        @media screen and (max-width: $size-max-m-1) {          
          margin: 0;
          padding: 0;
          transition: .5s;
        }
        div.content-products {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: flex-start;
            transition: .5s;
            width: 100%;
            @media screen and (max-width: $size-max-m-1) {
              justify-content: center;
              transition: .5s;
            }
            div.unique-product {
                padding: 10px 5vw;
                margin-bottom: 85px;
                transition: .5s;
                div.header-product {
                    width: 100%;
                    height: 65px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;;
                    border: 1px solid $color-1;
                    background: $color-3;
                    margin-bottom: 35px;
                    padding: 15px 25px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-2) {
                        height: 50px;
                        transition: .5s;
                    }
                    h3 {
                      @extend .font-3-normal;
                      font-family: $font-family-4;
                      font-size: $font-size-34;
                      color: $color-white;
                      transition: .5s;
                        @media screen and (max-width: $size-max-1) {
                            //font-size: 3.2vw;
                            transition: .5s;
                            @media screen and (max-width: $size-max-3) {
                                font-size: 2.2vw;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-3) {
                                    font-size: 16px;
                                    transition: .5s;
                                }
                            }
                        }
                    }
                }
                div.body-product {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;
                    transition: .5s;
                    &:nth-child(even) {
                      div.content-description {
                        p {
                          //text-align: justify!important;
                        }
                        h3, h2, h1 {
                          color: $color-1-2;
                          font-family: $font-family-4;
                        }
                        h3 {
                          font-size: $font-size-18;
                          @media screen and (max-width: $size-max-m-1) {
                            font-size: $font-size-20;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-2) {
                              font-size: $font-size-22;
                              transition: .5s;
                              @media screen and (max-width: $size-max-m-4) {
                                font-size: $font-size-24;
                                transition: .5s;
                              }
                            }
                          }
                        }
                        h2 {
                          font-size: $font-size-20;
                          @media screen and (max-width: $size-max-m-1) {
                            font-size: $font-size-22;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-2) {
                              font-size: $font-size-24;
                              transition: .5s;
                              @media screen and (max-width: $size-max-m-4) {
                                font-size: $font-size-26;
                                transition: .5s;
                              }
                            }
                          }
                        }
                        h1 {
                          font-size: $font-size-22;
                          @media screen and (max-width: $size-max-m-1) {
                            font-size: $font-size-24;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-2) {
                              font-size: $font-size-26;
                              transition: .5s;
                              @media screen and (max-width: $size-max-m-4) {
                                font-size: $font-size-28;
                                transition: .5s;
                              }
                            }
                          }
                        }
                      }
                    }
                    div.content-images {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-4) {
                          width: 100%;
                          flex-direction: column;
                          transition: .5s;
                        }
                        a {
                            width: 50%;                            
                            display: flex;
                            align-items: center;
                            justify-content: center;                            
                            max-height: 230px;
                            overflow: hidden;
                            transition: .5s;
                            @media screen and (max-width: $size-max-2) {
                              max-height: 13vw;
                              transition: .5s;
                            }
                            @media screen and (max-width: $size-max-m-1) {
                              max-height: 30vw;
                              transition: .5s;
                            }
                            @media screen and (max-width: $size-max-m-4) {
                              width: 100%;
                              max-height: 100%;
                              margin-bottom: 25px;
                              transition: .5s;
                            }
                            &:nth-child(1) {
                                margin-right: 1.5px;
                            }
                            &:nth-child(2) {
                                margin-left: 1.5px;
                            }
                            img {
                                width: 100%;
                                //transition: .5s;                                
                            }
                        }
                    }
                    div.content-description {
                        display: flex;
                        align-items: flex-start;
                        justify-content: center;
                        padding: 0 0;
                        transition: .5s;
                        @media screen and (max-width: $size-max-4) {
                            flex-direction: column;
                            transition: .5s;
                        }
                        div.content-paragraph {
                            width: 50%;
                            transition: .5s;
                            padding-right: 1vw;
                            &.full-unique {
                              width: 100%;
                            }
                            @media screen and (max-width: $size-max-4) {
                                padding: 0;
                                width: 100%;                                
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-1) {
                                  padding-top: 25px;                         
                                  transition: .5s;
                              }
                            }
                            p {
                                font-size: $font-size-16;
                                color: $color-text-gris;
                                @extend .font-3-normal;
                                margin-bottom: 1.5rem;
                                line-height: 1.5;
                                @media screen and (max-width: $size-max-m-1) {
                                  font-size: $font-size-18;
                                  margin-bottom: 10px;
                                  transition: .5s;
                                  @media screen and (max-width: $size-max-m-2) {
                                    font-size: $font-size-26;
                                    transition: .5s;
                                  }
                                }
                              }
                        }
                        div.content-list-bullets {
                            width: 50%;
                            transition: .5s; 
                            padding-top: 2vw;                           
                            @media screen and (max-width: $size-max-4) {
                                width: 100%;
                                padding: 0;
                                transition: .5s;
                            }
                            @media screen and (max-width: $size-max-m-4) {
                              padding: 0;
                              transition: .5s;
                          }
                          p {
                            font-size: $font-size-16;
                            color: $color-text-gris;
                            @extend .font-3-normal;
                            margin-bottom: 1.5rem;
                            line-height: 1.5;
                            @media screen and (max-width: $size-max-m-1) {
                              font-size: $font-size-18;
                              transition: .5s;
                              @media screen and (max-width: $size-max-m-2) {
                                font-size: $font-size-26;
                                transition: .5s;
                              }
                            }
                          }
                            ul {
                                width: 100%;
                                transition: .5s;
                                padding-left: 1.5vw;
                                padding-top: 3vw;
                                @media screen and (max-width: $size-max-4) {
                                    width: 100%;
                                    padding: 15px;
                                    transition: .5s;
                                }
                                li {
                                    width: 100%;
                                    text-transform: none;
                                    font-size: $font-size-16;
                                    color: $color-text-gris;
                                    @extend .font-3-normal;
                                    margin-bottom: 0;
                                    line-height: 1.5;
                                    transition: .5s;
                                    @media screen and (max-width: $size-max-m-2) {
                                        font-size: $font-size-26;
                                        transition: .5s;
                                    }
                                }
                            }
                        }
                    }
                }
                div.footer-product {
                    width: 100%;
                    display: flex;
                    min-width: 300px;
                    justify-content: flex-start;
                    align-items: flex-start;
                    flex-direction: column;
                    transition: .5s;
                    a {
                        width: 50%;
                        height: 65px;
                        padding: 7px;
                        transition: .5s;
                        align-items: center;
                        justify-content: center;
                        background: $color-black;
                        display: flex;
                        @media screen and (max-width: $size-max-3) {
                            width: 70%;
                            height: 55px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-1) {
                              max-width: 300px;
                              transition: .5s;
                              @media screen and (max-width: $size-max-m-3) {
                                width: 100%;
                                transition: .5s;
                            }
                          }
                        }
                        span {
                            width: 100%;
                            height: 100%;
                            transition: .5s;      
                            font-weight: 500;                      
                            color: $color-white;
                            font-size: $font-size-22;
                            border: 1px solid $color-1;
                            text-transform: uppercase;
                            justify-content: center;                            
                            align-items: center;
                            display: flex;
                            @media screen and (max-width: $size-max-3) {
                              font-size: $font-size-18;
                              transition: .5s;
                              @media screen and (max-width: $size-max-m-3) {
                                font-size: 16px;
                                transition: .5s;
                            }
                          }                            
                        }
                        &:hover {
                           // background: $color-1;
                            transition: .5s;
                            span {
                                //border: 1px solid $color-white;
                                color: $color-1;
                                transition: .5s;
                            }
                        }
                    } 
                    label {
                      width: 100%;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      margin-top: 25px;
                      transition: .5s;
                    }                   
                }
            }
        }
    }
}

section.section-tarifs-tarifs {
  padding-top: 95px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  transition: .5s;

  div.header-section-tarifs {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .5s;
    h3 {
      min-width: 300px;
      font-weight: 700;
      position: absolute;
      color: $color-3;
      font-size: 2.5vw;
      text-align: center;
      line-height: 1;
      /*
      text-shadow: 2px 2px 5px $color-black,
                   -2px -2px 5px $color-black,
                   2px -2px 5px $color-black,
                   -2px 2px 5px $color-black;
                   */
      //// Modif - code                
      width: 81%;
      display: flex;
      min-height: 90px;
      padding: 1.7vw 15px;              
      align-items: center; 
      @extend .font-3-normal; 
      justify-content: flex-start;
      border: 0 solid $color-white;              
      font-family: $font-family-4;
      transition: .5s;
      margin-top: 0;      
      @media screen and (max-width: $size-max-2) {
          transition: .5s;
          @media screen and (max-width: $size-max-4) {
            justify-content: center;
            text-align: center;
              transition: .5s;
              @media screen and (max-width: $size-max-m-1) {
                  width: 100%;
                  font-size: 28px;
                  justify-content: center;
                  text-align: center;
                  padding: 15px;
                  transition: .5s;
                  @media screen and (max-width: $size-max-m-2) {
                      font-size: 28px;
                      transition: .5s;
                      @media screen and (max-width: $size-max-m-4) {
                          font-size: 28px;
                          transition: .5s;
                      }
                  }
              }
          }
      }
  } 
  }

  div.container-fluid {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    transition: .5s;
    padding: 65px;
    
    div.col-md-3.colonne-tarifs {      
      padding: 0;
      margin: 1px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center; 
      border: 1px solid $color-1; 
      background: $color-white;
      margin-bottom: 75px;      
      position: relative;
      transition: 1.2s; 
      max-width: 300px;
      min-width: 300px;
      z-index: 65;
      @media screen and (max-width: $size-max-3) {
        margin-bottom: 100px;
        transition: .5s;
      } 
      h3.title-forfait {        
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;        
        @extend .font-3-normal;
        font-family: $font-family-4;
        justify-content: flex-start;
        background: $color-3;
        color: $color-white;        
        padding: 15px 15px;
        font-weight: 500;
        text-align: left;
        font-size: 20px;
        transition: .5s;
      }
      div.pt-2.price-details {
        width: 100%;
        height: 115px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        background: url(../images/bg_tarifs_box01.jpg) no-repeat center center;
        background-size: cover;
        transition: .5s;
        label {
          width: 100%;
          text-align: center;
          &:nth-child(1) {
            font-size: 60px;
            font-weight: 800;
            color: $color-1;
            transition: .5s;
          }
          &:nth-child(2) {
            font-size: 16px;
            font-weight: 500;
            color: $color-grey;
            transition: .5s;
          }
          small {
            font-size: 18px;
            text-align: center;
          }
        }
      }
      div.pt-3.prod-details {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-top: 2px solid $color-white;
        background: url(../images/bg_tarifs_box02.jpg) no-repeat center center;
        background-size: cover;
        text-align: center;
        transition: .5s;
        h3 {
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          color: $color-grey;
          font-weight: 400;
          font-size: 16px;
          transition: .5s;          
          width: 100%;
        }
      }
      div.pt-4.footer-tarif {
        width: 100%;
        height: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .5s;
        a {
          width: 200px;
          padding: 15px;
          font-size: 18px;
          text-align: center;
          background: $color-1;
          border: 1px solid $color-1;
          text-transform: uppercase;
          color: $color-white;
          transition: .5s;
        }
      }
      div.content-shadow {
        position: absolute;
        transition: .7s;
        bottom: -85px;
        img {transition: 1.2s; }
      }
      &:hover {
        -webkit-transform: scale(1.2);
        -ms-transform: scale(1.2);
        transform: scale(1.2);
        transition: .7s;
        z-index: 75;

        -webkit-box-shadow: 0px 3px 14px 0px rgba(0, 0, 0, 0.31);
        -moz-box-shadow:    0px 3px 14px 0px rgba(0, 0, 0, 0.31);
        box-shadow:         0px 3px 14px 0px rgba(0, 0, 0, 0.31);
        @media screen and (max-width: $size-max-m-4) {
          -webkit-transform: scale(1.0);
          -ms-transform: scale(1.0);
          transform: scale(1.0);
          transition: .7s;
        }

        div.content-shadow {
          transition: .7s;
          img {
            -webkit-transform: scale(1.2);
            -ms-transform: scale(1.2);
            transform: scale(1.2);
            transition: .7s;
          }
        }

        h3.title-forfait { 
          background: $color-white;
          color: $color-3; 
          transition: .7s;
        }
        div.pt-2.price-details {
          background-size: 0 0;
          transition: .7s;          
        }
        div.pt-3.prod-details {
          background-size: 0 0;
          transition: .7s;
        }
        div.pt-4.footer-tarif {
          transition: .7s;
          a {
            background: $color-white;
            border: 1px solid $color-1;
            color: $color-1;
            transition: .7s;
          }
        }
      }
    }
  }
}


section.section-02-tarifs {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .5s;
  div.container-fluid.row {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    transition: .5s;
    div.content-unique-group {
      width: 80%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 85px;
      transition: .5s;
      @media screen and (max-width: $size-max-m-1) {
        width: 90%;
        transition: .5s;
        @media screen and (max-width: $size-max-m-2) {
          width: 90%;
          flex-direction: column;
          transition: .5s;
        }
      }
      div.left-side-content-group-tarifs-02 {
        width: 40%;
        transition: .5s;
        @media screen and (max-width: $size-max-3) {
          width: 45%;
          transition: .5s;
          @media screen and (max-width: $size-max-m-1) {
            width: 50%;
            transition: .5s;
            @media screen and (max-width: $size-max-m-2) {
              width: 100%;
              transition: .5s;
            }
          }
        }
        div.box-group {
          width: 100%;
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          justify-content: center;
          transition: .5s; 
          @media screen and (max-width: $size-max-m-2) {
            width: 100%;
            align-items: center;
            margin-bottom: 35px;
            transition: .5s;
          }  
          
          div.body-box {
            width: 100%;
            @media screen and (max-width: $size-max-m-2) {
              width: 100%;
              display: flex;
              align-items: center;
              flex-direction: column;
              justify-content: center;
              transition: .5s;
            }
            a {
              img {
                transition: .5s;
              }
              @media screen and (max-width: $size-max-m-2) {
                width: 100%;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                transition: .5s;
                img {
                  width: 100%;
                  transition: .5s;
                }
              }
            }
          }
          

          div.header-box {
            width: 100%;
            height: 65px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid $color-1;
            background: $color-3;
            margin-bottom: 5px;            
            transition: .5s;
            padding: 0 15px; 
            @media screen and (max-width: $size-max-2) {
              height: 55px;
              transition: .5s;
            }           
            h3 {
              width: 100%;
              text-align: left;
              font-family: $font-family-4;              
              color: $color-white;
              transition: .5s;
              font-size: 25px;
              @media screen and (max-width: $size-max-2) {
                font-size: 22px;
                transition: .5s;
              }
            }
          }
        }
      }
      div.right-side-tarifs-02 {
        width: 60%;
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        justify-content: space-between;
        transition: .5s;
        @media screen and (max-width: $size-max-3) {
          width: 55%;
          transition: .5s;
          @media screen and (max-width: $size-max-m-1) {
            width: 50%;
            transition: .5s;
            @media screen and (max-width: $size-max-m-2) {
              width: 100%;
              transition: .5s;
            }
          }
        }
        div.content-paragraph {
          width: 90%;
          height: 65%;
          transition: .5s;
          padding-top: 3vw;
          @media screen and (max-width: $size-max-3) {
            padding-top: 0;
            transition: .5s;
            @media screen and (max-width: $size-max-m-2) {
              width: 100%;
              transition: .5s;
            }
          }

          p {
            text-align: justify!important;
          }
          h3, h2, h1 {
            color: $color-1-2;
            font-family: $font-family-4;
          }
          h3 {
            font-size: $font-size-18;
            @media screen and (max-width: $size-max-m-1) {
              font-size: $font-size-20;
              transition: .5s;
              @media screen and (max-width: $size-max-m-2) {
                font-size: $font-size-22;
                transition: .5s;
                @media screen and (max-width: $size-max-m-4) {
                  font-size: $font-size-24;
                  transition: .5s;
                }
              }
            }
          }
          h2 {
            font-size: $font-size-20;
            @media screen and (max-width: $size-max-m-1) {
              font-size: $font-size-22;
              transition: .5s;
              @media screen and (max-width: $size-max-m-2) {
                font-size: $font-size-24;
                transition: .5s;
                @media screen and (max-width: $size-max-m-4) {
                  font-size: $font-size-26;
                  transition: .5s;
                }
              }
            }
          }
          h1 {
            font-size: $font-size-22;
            @media screen and (max-width: $size-max-m-1) {
              font-size: $font-size-24;
              transition: .5s;
              @media screen and (max-width: $size-max-m-2) {
                font-size: $font-size-26;
                transition: .5s;
                @media screen and (max-width: $size-max-m-4) {
                  font-size: $font-size-28;
                  transition: .5s;
                }
              }
            }
          }

          p {
            color: $color-3;
            font-size: 20px;
            font-weight: 400;
            line-height: 30px;
            transition: .5s;
            @media screen and (max-width: $size-max-2) {
              font-size: 18px;
              line-height: 25px;
              transition: .5s;
              @media screen and (max-width: $size-max-4) {
                font-size: 16px;
                line-height: 22px;
                transition: .5s;
                @media screen and (max-width: $size-max-m-2) {
                  font-size: 15px;
                  line-height: 20px;
                  transition: .5s;
                }
              }
            }
          }
        }
        div.footer-box {
          width: 100%;
          height: 25%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-top: 2vw;
          transition: .5s;
          @media screen and (max-width: $size-max-m-2) {
            justify-content: flex-start;
            transition: .5s;
          }
          a.btn-border {
            width: 20vw;
            height: 65px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: $color-black;
            border: 1px solid $color-1;
            min-width: 300px;
            transition: .5s;
            padding: 7px;
            @media screen and (max-width: $size-max-2) {
              width: 18vw;
              height: 55px;
              transition: .5s;
            }
            span {
              width: 100%;
              height: 55px;
              display: flex;
              font-size: 20px;
              align-items: center;
              justify-content: center;
              border: 1px solid $color-1;
              text-transform: uppercase;              
              color: $color-white;
              font-weight: 500;
              transition: .5s;  
              @media screen and (max-width: $size-max-2) {
                width: 100%;
                height: 45px;
                font-size: 18px;
                transition: .5s;
              }            
            }
            &.empty {
              background: $color-grey-light;
              border: 1px solid $color-white;
              span {
                color: $color-white;
                border: 1px solid $color-white;
                cursor: normal;
              }
              &:hover {
                transition: .5s;
                span {
                  color: $color-white;
                  transition: .5s;
                }
              }
            }
            &:hover {
              transition: .5s;
              span {
                color: $color-1;
                transition: .5s;
              }
            }
          }
        }
      }
    }
  }
}
.WYSIWYG {
  p {
    font-weight: 400;
    line-height: 32px;
    font-size: 22px;
    transition: .5;
    @media screen and (max-width: $size-max-2) {
      font-size: 18px;
      line-height: 25px;
      transition: .5s;
      @media screen and (max-width: $size-max-4) {
        font-size: 16px;
        line-height: 22px;
        transition: .5s;
        @media screen and (max-width: $size-max-m-2) {
          font-size: 15px;
          line-height: 20px;
          transition: .5s;
        }
      }
    }
  }

  ul {
    li {
      text-transform: none;
      font-weight: 400;
      line-height: 32px;
      font-size: 22px;
      transition: .5;
      @media screen and (max-width: $size-max-2) {
        font-size: 18px;
        line-height: 25px;
        transition: .5s;
        @media screen and (max-width: $size-max-4) {
          font-size: 16px;
          line-height: 22px;
          transition: .5s;
          @media screen and (max-width: $size-max-m-2) {
            font-size: 15px;
            line-height: 20px;
            transition: .5s;
          }
        }
      }
    }
  }
}



body.team-page {
  .section-01 {
    div.left-side {
      z-index: 99;
      width: 60%;      
      display: flex;
      align-items: center;
      background-color: $color-white;
      justify-content: center;      
      flex-direction: column;
      padding: 15px 6vw;
      @media screen and (max-width: $size-max-2) {
        padding: 15px 4vw;
        transition: .5s;
        @media screen and (max-width: $size-max-3) {
          padding: 15px 2vw;
          transition: .5s;
          @media screen and (max-width: $size-max-4) {
            width: 50%;  
            transition: .5s;
            @media screen and (max-width: $size-max-m-2) {
              width: 100%;
              transition: .5s;
            }
          }
        }
      }
      div.content-two-bursts {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .5s;
        @media screen and (max-width: $size-max-3) {
          flex-wrap: wrap;
          transition: .5s;
        }
      }
      div.text-box {
        @media screen and (max-width: 1299px) {
          width: 92%;
          transition: .5s;
        }
        p {
          @media screen and (max-width: $size-max-m-2) {
            font-size: $font-size-24;
            transition: .5s;
          }
        }
        h3 {
          font-size: $font-size-32;
          transition: .5s;
        }
        h3.title-header {
          margin-top: -2vw;
          font-size: $font-size-50;
          transition: .5s;
          @media screen and (max-width: 980px) {
            margin-top: 3vw;
            transition: .5s;
          }
        }
      }
      div.burst-left-team,
      div.burst-right-team {
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .5s;
        div.burst_bg {
          transition: .5s;
          min-width: 250px;
          &::before {
            content: "";              
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 0;
            position: absolute;
            background: rgb(41, 131, 53);
            transition: .5s;
            opacity: 0;
          }
          &:hover {
            &::before {
              opacity: 1;
              transition: .5s;
            }
            div.border {
              border: 1px solid $color-white;
              transition: .5s;              
            } 
            h3, h4 {
              text-shadow: none;
              transition: .5s;
            }           
          }
          h3, h4 {
            transition: .5s;
          }           
        }
        div.border {
          div.text {
            display: flex;
            align-items: center;
            justify-content: center;
            transition: .5s;
            a.content-elts {
              width: 100%;
              display: flex;
              align-items: center;
              flex-direction: column;
              justify-content: center;
            }
            h3, h4 {
              @extend .font-3-normal;
              font-family: $font-family-4;
              text-align: center;
              transition: .5s;
              @media screen and (max-width: $size-max-2) {
                font-size: 1.5vw;
                transition: .5s;
                @media screen and (max-width: $size-max-4) {
                  font-size: 2.5vw;
                  transition: .5s;
                  @media screen and (max-width: $size-max-m-3) {
                    font-size: 5.5vw;
                    transition: .5s;
                  }
                }
              }
            }
          }
        }
      }
      div.burst-right-team {
        div.burst_bg {
          &::before {
            content: "";              
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 0;
            position: absolute;
            background: rgb(41, 131, 53);
            transition: .5s;
            opacity: 0;
          }
          &:hover {
            &::before {
              opacity: 1;
              transition: .5s;
            }
            div.border {
              border: 1px solid $color-white;
              transition: .5s;              
            }            
          }
        }
        div.border {          
          div.text {
            h3, h4, p {
              text-shadow: none;
              @extend .font-3-normal;
              font-family: $font-family-4;
              text-align: center;
              transition: .5s;
              z-index: 99;
            }
            h4.title {
              font-size: 1.9vw;
              @media screen and (max-width: $size-max-4) {
                font-size: 2.4vw;
                transition: .5s;
                @media screen and (max-width: $size-max-m-3) {
                  font-size: 6.4vw;
                  transition: .5s
                }
              }
            }
            h4.subtitle {
              font-size: 1.8vw;
              @media screen and (max-width: $size-max-m-3) {
                font-size: 5.4vw;
                transition: .5s
              }
            }
            p.none-style {
              font-size: 22px;
              font-family: $font-family-1;           
              text-transform: none;
              font-style: normal;
              font-weight: 400;               
              margin-top: 25px;     
                       
              a {
                font-size: 18px; 
              }
              @media screen and (max-width: $size-max-2) {
                font-size: 20px;
                margin-top: 10px;
                transition: .5s;
                a {
                  font-size: 16px; 
                }
                @media screen and (max-width: $size-max-3) {
                  font-size: 18px;
                  margin: 0;
                  transition: .5s;
                  a {
                    font-size: 14px;
                  }
                  @media screen and (max-width: $size-max-m-3) {
                    font-size: 20px;
                    margin: 0;
                    transition: .5s;
                    a {
                      font-size: 14px; 
                      @media screen and (max-width: $size-max-m-3) {
                        font-size: 20px;
                        transition: .5s
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    div.right-side {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 10vw;
      transition: .5s;
      div.burst-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transition: .5s;
        margin: 0
      }

      div.burst-carousel-content {
        div.owl-item.active {
          a.delimeter-img {
            display: flex;
            justify-content: center;
            align-items: center;
            @media screen and (max-width: $size-max-m-2) {
              img {
                width: 86%;
                transition: .5s;
                @media screen and (max-width: $size-max-m-3) {
                  width: 100%;
                  transition: .5s;
                }
              }
            }
          }
        }
        div.burst_bg {
          position: relative;   
          min-height: 300px;
          height: 16vw;
          @media screen and (max-width: $size-max-2) {
            min-height: 250px;
            transition: .5s;
            @media screen and (max-width: $size-max-4) {
              min-height: auto;
              height: auto;
              transition: .5s;
            }
          }
          a {
            img {
              height: 100%;
              @media screen and (max-width: $size-max-4) {
                height: auto;
                transition: .5s;
              }
              transition: .5s;
            }
          }
          &::before {
            content: "";              
            top: -10%;
            left: -10%;
            right: 0;
            bottom: 0;
            z-index: 0;
            position: absolute;
            background: rgba(41, 131, 53, 0.85098039215686275);
            transition: .5s;
            opacity: 0;
          }
          &:hover {
            &::before {
              opacity: 1;
              transition: .5s;
            }
            div.border {
              border: 1px solid $color-white;
              transition: .5s;              
            }            
          }       
          div.border {           
            z-index: 99;
            &::before {
              content: "";              
              top: -10%;
              left: -10%;
              right: 0;
              bottom: 0;
              z-index: 0;
              position: absolute;
              //background: rgba(57, 181, 74, 0.65098039215686275);
              transition: .5s;
              opacity: 0;
              height: 125%;
              width: 125%;
            }
          }
          div.text {   
            position: relative;         
            a {
              z-index: 15;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              transition: .5s;              
              h4 {
                text-shadow: none;
                @extend .font-3-normal;
                font-family: $font-family-4;
                text-align: center;
                transition: .5s;
                &.title {
                  font-size: $font-size-38;
                }
                &.title-pt-2 {
                  font-size: $font-size-28;
                }
                &.subtitle {
                  margin-top: 15px;
                  font-size: $font-size-20;
                  text-align: justify;
                }
              }
            }

            p.none-style {
              text-shadow: none;
                a {
                  text-shadow: none;
                }
              }

          }
        }
      }
    }

    div.burst-left-team {
      @media screen and (max-width: $size-max-m-2) {
        width: 50%;
        transition: .5s;
        @media screen and (max-width: $size-max-m-3) {
          width: 100%;
          transition: .5s;
        }
      }
    }

    div.burst-right-team {
      @media screen and (max-width: $size-max-m-2) {
        width: 50%;
        transition: .5s;
        @media screen and (max-width: $size-max-m-3) {
          width: 100%;
          transition: .5s;
        }
      }
    }
  }
}

section.section-logos-bottom {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $color-1;
  transition: .5s;
  border-bottom: 15px solid$color-1;
  div.container-fuid.row {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    transition: .5s;
    div.header-section-logos {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      transition: .5s;
      padding: 15px;
      h3 {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-size: $font-size-22;
          color: $color-white;
          @extend .font-3-normal;
          font-family: $font-family-4;
          transition: .5s;  
          @media screen and (max-width: $size-max-m-2) {
              //text-align: center;
              padding-bottom: 0;
              transition: .5s;
          } 
      }
    }
    div.logos-content {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $color-white;
      padding: 25px 0;
      transition: .5s;
      @media screen and (max-width: $size-max-m-2) {
        flex-wrap: wrap;
        transition: .5s;
      }
      div {
        transition: .5s;
        @media screen and (max-width: $size-max-m-2) {
          width: 45%;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: .5s;
        }
      }
    }
  }
}

section.section-header.index {
  div.module-header {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      .caption {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          z-index: 99;
          text-align: center;
          transform: translateY(0%);
          display: none;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          h4 {
              color: $color-white;
              text-shadow: 2px 2px 5px $color-black,
                           -2px -2px 5px $color-black,
                           2px -2px 5px $color-black,
                           -2px 2px 5px $color-black;
          }
  
          h3 {
              min-width: 300px;
              font-weight: 700;
              position: absolute;
              color: $color-3;
              font-size: 2.5vw;
              text-align: center;
              line-height: 1;
              /*
              text-shadow: 2px 2px 5px $color-black,
                           -2px -2px 5px $color-black,
                           2px -2px 5px $color-black,
                           -2px 2px 5px $color-black;
                           */
              //// Modif - code                
              width: 100%;
              display: flex;
              min-height: 90px;
              padding: 1.7vw 6vw;              
              align-items: center; 
              @extend .font-3-normal; 
              justify-content: flex-start;
              border: 0 solid $color-white;              
              font-family: $font-family-4;
              margin-top: 18vw;
              transition: .5s;
              @media screen and (max-width: 1880px) {
                padding-left: 10vw;
                transition: .5s;
              }
              @media screen and (max-width: $size-max-2) {
                  //width: 40vw;
                  transition: .5s;
                  @media screen and (max-width: $size-max-3) {
                      //width: 50vw;
                      transition: .5s;
                      @media screen and (max-width: $size-max-m-1) {
                          //width: 60vw;
                          font-size: 28px;
                          transition: .5s;
                          @media screen and (max-width: $size-max-m-2) {
                              font-size: 28px;
                              transition: .5s;
                              @media screen and (max-width: $size-max-m-4) {
                                  font-size: 28px;
                                  transition: .5s;
                              }
                          }
                      }
                  }
              }
          }    
      }
  }
}

section.section-coordonnees {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  transition: .5s;
  
  div.header-section-coordonnees {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    transition: .5s;
    h3 {
      min-width: 300px;
      font-weight: 700;
      position: absolute;
      color: $color-3;
      font-size: 2.5vw;
      text-align: center;
      line-height: 1;
      //// Modif - code                
      width: 81%;
      display: flex;
      min-height: 90px;
      padding: 1.7vw 15px;              
      align-items: center; 
      @extend .font-3-normal; 
      justify-content: flex-start;
      border: 0 solid $color-white;              
      font-family: $font-family-4;
      transition: .5s;
      margin-top: 80px;      
      margin-left: -10px;      
      @media screen and (max-width: $size-max-2) {
          font-size: 3.5vw;
          transition: .5s;
          @media screen and (max-width: $size-max-4) {
            justify-content: flex-start;
            text-align: left;
              transition: .5s;
              @media screen and (max-width: $size-max-m-1) {
                  width: 100%;
                  font-size: 28px;
                  justify-content: center;
                  text-align: center;
                  padding: 15px;
                  transition: .5s;
                  @media screen and (max-width: $size-max-m-2) {
                      font-size: 28px;
                      transition: .5s;
                      @media screen and (max-width: $size-max-m-4) {
                          font-size: 28px;
                          transition: .5s;
                      }
                  }
              }
          }
      }
  } 
  }

  div.container-fluid {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .5s;
    padding: 0;
    @media screen and (max-width: $size-max-m-1) {
      flex-direction: column;
      transition: .5s;
    }

    div.left-side-informations {
      width: 60%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      transition: .5s;
      @media screen and (max-width: $size-max-m-1) {
        width: 100%;
        transition: .5s;
      }
      h3.title-adress {
        width: 67%;
        display: flex;
        color: $color-3;
        align-items: center;        
        @extend .font-3-normal; 
        font-size: $font-size-38;           
        font-family: $font-family-4;
        justify-content: flex-start;
        margin-bottom: 25px;
        text-align: left;
        transition: .5s;
        margin-top: 5vw;
        @media screen and (max-width: $size-max-m-1) {
          justify-content: center;
          text-align: center;
          margin-top: 125px;
          transition: .5s;
          width: 100%;
        }
      }
      div.content-top-infos {
        width: 67%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: .5s;   
        @media screen and (max-width: $size-max-m-1) {
          width: 100%;
          flex-direction: column;
          transition: .5s;
        } 
        div.content-infos,
        div.content-img {
          width: 50%;
          transition: .5s;
          @media screen and (max-width: $size-max-m-1) {
            width: 100%;
            transition: .5s;
          }
        }
        div.content-infos {
          h3 {
            width: 100%;
            transition: .5s;
            @media screen and (max-width: $size-max-m-1) {
              width: 100%;
              text-align: center;
              transition: .5s;
            }
          }
          h3.local-adrress {            
            color: $color-3;
            @extend .font-3-normal;
            font-size: $font-size-22;
            font-family: $font-family-4;
            margin-bottom: 25px;
            transition: .5s;
          }
          h3.phone {
            margin-bottom: 5px;
            a {
              color: $color-1;
              @extend .font-3-normal;
              font-size: $font-size-42;
              font-family: $font-family-1;
              margin-bottom: 15px;
              font-weight: 600;
              transition: .5s;
              &:hover {
                color: $color-3;
                transition: .5s;
              }
            }
          }
          h3.email-contact {
            transition: .5s;
            a {
              color: $color-1;
              @extend .font-3-normal;
              font-size: $font-size-24;
              font-family: $font-family-1;
              margin-bottom: 15px;
              font-weight: 400;
              transition: .5s;
              &:hover {
                color: $color-3;
                transition: .5s;
              }
            }
          }
        }   
        div.content-img {
          transition: .5s;
          @media screen and (max-width: $size-max-m-1) {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 35px;
            transition: .5s;
          }
        }     
      }

      div.content-points-points.contact-page {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 3vw;
        margin-bottom: 0;
        transition: .5s;
      }

      div.content-bottom-infos {
        width: 67%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        margin-bottom: 34px;
        margin-top: 34px;
        transition: .5s;
        @media screen and (max-width: $size-max-m-1) {
          width: 100%;
          justify-content: center;
          align-items: center;
          transition: .5s;
        }
        h3.title-work-time {
          color: $color-3;
          @extend .font-3-normal;
          font-size: $font-size-30;
          font-family: $font-family-4;
          margin-bottom: 25px;
          margin-top: 25px;
          transition: .5s;
          @media screen and (max-width: $size-max-m-1) {
            width: 100%;
            text-align: center;
            transition: .5s;
          }
        }

        div.horaire-time {
          width: 100%;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          transition: .5s;
          h3 {
            width: 100%;
            color: $color-3;
            text-transform: none;
            @extend .font-3-normal;
            font-size: $font-size-22;
            font-family: $font-family-1;
            margin-bottom: 15px;
            margin-top: 5px;
            transition: .5s;
            span {
              color: $color-1;
            }
            @media screen and (max-width: $size-max-m-1) {
              margin-bottom: 35px;
              text-align: center;
              transition: .5s;
            }
          }
        }
      }
    }

    div.content-map {
      width: 40%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      @media screen and (max-width: $size-max-m-1) {
        width: 100%;
        transition: .5s;
      }
      div.module-google-map {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        // background: $color-1;
        transition: .5s;

        div#map-canvas {
          width: 100%;
          transition: .5s;
        }
      }
    }
  }
}

section.section-01-certificats {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .5s;
  div.container-fluid.row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    transition: .5s;
    div.content-img-left,
    div.content-img-right {
      width: 40%;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 450px;
      transition: .5s;
      a {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .5s;
        img {
          width: 100%;
        }
      }
    }
  }
}

div.content-empty {
  width: 100%;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 350px;
  transition: .5s;
  h3 {
    width: 50%;
    text-align: center;
    text-transform: none;
    color: $color-grey-light;
    font-weight: 400;
    font-size: 18px;
    transition: .5s;
    @media screen and (max-width: $size-max-m-1) {
      width: 100%;
      transition: .5s;
    }
  }
}

//// MAP STYLE 
div#bodyContent {
  p {
    a {
      transition: .5s;
      &:hover {
        color: $color-3;
        transition: .5s;
      }
    }
  }
}

div.content-points-points {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 2vw;
  margin: 2vw 15px;
  margin-top: 0;
  transition: .5s;
}


/** Add this class to any anchor tag so it clear the fixed menu (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 1;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}

/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or module-menu-centre.php
/*****************************************************/
/* purgecss start ignore */
@import 'modules/menu';
@import 'sections/section-iframes-bookeo';
// @import 'menu-centre';
/* purgecss end ignore */

/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

//@import 'sections/section-text-image';

//@import 'sections/section-bursts';

/*****************************************************/
/* Sections (to use with /modules/module-*-*.php)
/*****************************************************/
@import 'modules/module-slideshow';
//@import 'modules/module-soumission';
//@import 'modules/module-header';



/*******************************************************************************
* COMMON TO ALL PAGES
*/


/*****************************************************/
/* INDEX.PHP
/*****************************************************/
.section-01 {
  background-color: $color-white;

  div.container-fluid.row {
    display: flex;
    align-items: stretch;
    justify-content: center;
    margin: 0;
    padding: 0;
    @media screen and (max-width: $size-xs-max){
      flex-direction: column;
    }
  }

  div.left-side {
    z-index: 99;
    width: 60%;
    padding: 0 0 30px 10%;
    background-color: $color-white;
    div.text-box {
      padding-top: 4rem;
      padding-right: 10%;
      h3 {
        font-size: $font-size-40;
        color: $color-text-gris;
        @extend .font-3-normal;
        font-family: $font-family-4;
        margin-bottom: 2rem;
      }

      p {
        font-size: $font-size-20;
        color: $color-text-gris;
        @extend .font-3-normal;
        margin-bottom: 1.5rem;
        line-height: 1.5;
      }
    }
  }

  div.right-side {
    z-index: 99;
    width: 40%;
    background-image: linear-gradient(to bottom, #fff, #141d1f);
    div.burst-content {
      margin: auto;
    }
  }



  div.burst-content {
    width: 400px;
    transition: .3s;
    margin-top: -80px!important;
  }

  div.owl-carousel {
    margin-bottom: 4rem;
    transform: scale(1.2);
    @media screen and (max-width: 991px){
      transform: scale(1);
    }
  }

  /// Burst bg-color-solid style

  div.burst {
    background-color: $color-grey-dark;
    padding: 10px;
    transition: .3s;
    margin-bottom: 4rem;
    .border {
      display: flex;
      transition: .3s;
      align-items: center;
      justify-content: flex-start;
      border: 1px solid $color-primary;
      padding: 15px;
      div.icon {
        width: 40%;
        img {margin: auto;}
      }
      div.text {
        width: 60%;
        text-align: center;
      }
      h4.title {
        text-transform: uppercase;
        font-size: $font-size-20;
        @extend .font-1-bold;
        color: $color-white;
      }
      h4.subtitle {
        text-transform: uppercase;
        font-size: $font-size-22;
        @extend .font-1-normal;
        color: $color-white;
      }
    }
    &:hover {
      background-color: $color-primary;
      transition: .3s;
      .border {
        transition: .3s;
        border: 1px solid $color-white;
      }
    }
  }

/// Burst bg-image style
  .burst_bg {
    position: relative;
    margin: auto;
    margin-bottom: 3rem;
    max-width: 530px;
    overflow: hidden;
    img {
      width: 100%;
      transition: .3s;
    }

    &.over:hover {
      img {
        //transform: scale(1.2);
        opacity: .8;
        transition: .3s;
      }
    }

    div.border {
      top: 10px;
      left: 10px;
      right: 10px;
      bottom: 10px;
      position: absolute;
      border: 1px solid $color-primary;
      padding: 15px;
    }

    div.text {
      display: flex;
      height: 100%;
      align-items: inherit;
      flex-direction: column;
      justify-content: space-between;

      h4 {
        text-transform: uppercase;
        font-size: $font-size-22;
        color: $color-white;
        text-align: right;
        text-shadow:  -7px -7px 7px $color-primary,
                      7px 7px 7px $color-primary,
                      -7px 7px 7px $color-primary,
                      7px -7px 7px $color-primary;
        &.title {
          @extend .font-1-bold;
        }
        &.subtitle {
          @extend .font-1-normal;
        }
      }

      p {
        color: $color-white;
        @extend .font-3-normal;
        font-style: italic;
        margin-right: auto;
        text-shadow:  -7px -7px 7px $color-primary,
                      7px 7px 7px $color-primary,
                      -7px 7px 7px $color-primary,
                      7px -7px 7px $color-primary;
      }
    }


  }

  div.slogan {
    h3 {
      text-align: center;
      font-size: $font-size-40;
      color: $color-white;
      @extend .font-3-normal;
      font-family: $font-family-4;
    }
    padding-bottom: 30px;
  }


/// mediaquerys section-01

@media screen and (max-width: $size-md-max) {
  div.right-side, div.left-side {
    width: 50%;
    padding: 0;
    display: flex;
    transition: .3s;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    div.text-box {
      width: 400px;
      padding-right: 0;
      transition: .3s;
      p {
        text-align: justify;
        transition: .3s;
      }
    }
    @media screen and (max-width: $size-sm-max){

      div.text-box,
      div.burst-content {
        width: 320px;
        transition: .3s;
        padding: 0;
      }

      @media screen and (max-width: $size-xs-max){
        width: 100%;
        transition: .3s;

        div.text-box,
        div.burst-content {
          width: 80%;
          transition: .3s;
          margin-top: 30px!important;
        }
        @media screen and (max-width: $size-slider-breakpoint){

          div.text-box,
          div.burst-content {
            width: 90%;
            transition: .3s;
          }
        }
      }
    }
  }
}
///end mediaquerys section-01

}



/*******************************************************************************
* FAQ
*******************************************************************************/

#section-faq {
    padding: 50px 5% 150px 5%;
    .faq {
        #accordion {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 15px;
            @media screen and (max-width: $size-md-max){
                grid-template-columns: repeat(1, 1fr);
                grid-gap: 25px;
            }
            .panel.panel-default {
                a.accordion-toggle {
                    width: 100%;
                    background: $color-1;
                    height: 85px;
                    padding: 0 25px;
                    .title {
                        font-weight: bold;
                        font-size: $font-size-18;
                        color: #EDEDED;
                        text-transform: uppercase;
                        line-height: 1.5;
                        padding-right: 35px;
                    }
                    .plus h4 {
                        color: #EDEDED;
                        font-size: $font-size-28;
                    }
                    @media screen and (max-width: $size-xs-max){
                        height: 95px;
                    }
                }
                .panel-body {
                    p {
                        font-weight: normal;
                        font-size: $font-size-16;
                        color: #231F20;
                        padding-top: 10px;
                    }
                }
            }
        }
    }
    @media screen and (max-width: $size-xs-max){
        padding: 50px 5% 100px 5%;
    }
}
/*******************************************************************************
* X PAGE
*/

.pageX {
    section.header {

    }
}


/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: 1425px) {
   
nav.module-menu .menu-desktop .wrapper.right .main-menu>ul li>a {
    padding-left: 0.6rem;
    padding-right: 0.6rem;
}
}

@media screen and (max-width: 1305px) {
   
nav.module-menu .menu-desktop .wrapper.right .main-menu > ul li > a {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}
}

@media screen and (max-width: $size-md-max) {
   
nav.module-menu .menu-desktop .wrapper.right .main-menu > ul li > a {
    padding-left: 1rem;
    padding-right: 1rem;
}
}

@media screen and (max-width: $size-sm-max) {
    

}

@media screen and (max-width: $size-xs-max) {
   

}
