section.section-reservations {
  padding-top: 95px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  transition: .5s;

  div.header-section-reservations {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .5s;
    h3 {
      min-width: 300px;
      font-weight: 700;
      position: absolute;
      color: $color-3;
      font-size: 2.5vw;
      text-align: center;
      line-height: 1;
      //// Modif - code                
      width: 81%;
      display: flex;
      min-height: 90px;
      padding: 1.7vw 15px;              
      align-items: center; 
      @extend .font-3-normal; 
      justify-content: flex-start;
      border: 0 solid $color-white;              
      font-family: $font-family-4;
      transition: .5s;
      margin-top: 0;      
      @media screen and (max-width: $size-max-2) {
          transition: .5s;
          @media screen and (max-width: $size-max-4) {
            justify-content: center;
            text-align: center;
              transition: .5s;
              @media screen and (max-width: $size-max-m-1) {
                  width: 100%;
                  font-size: 28px;
                  justify-content: center;
                  text-align: center;
                  padding: 15px;
                  transition: .5s;
                  @media screen and (max-width: $size-max-m-2) {
                      font-size: 28px;
                      transition: .5s;
                      @media screen and (max-width: $size-max-m-4) {
                          font-size: 28px;
                          transition: .5s;
                      }
                  }
              }
          }
      }
  } 
  }

  div.container-fluid {
    width: 87%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    transition: .5s;
    padding: 65px;
  }

  div#bookeo_position {
    width: 100%!important;    
  }
  iframe#easyXDM_axiomct_project_default2615_provider {
    width: 100%!important;
    html {
      width: 100%!important;
    }
  }
  iframe#axiom_frame {
    width: 100%!important;
    html {
      width: 100%!important;
    }
  }
}